// Styles for header, search and language selector
@mixin form-controls-style() {
  background: $theme-bg-lighter;
  border-color: $theme-bg-lighter;
  color: $navbar-default-link-hover-color;
}

// Adds text shadow to links, which have custom underline colour to break the line for type decenders
@mixin textShadowToCropUnderline($shadow-color) {
  text-shadow: .03em 0 $shadow-color, -.03em 0 $shadow-color, 0 .03em $shadow-color, 0 -.03em $shadow-color, .06em 0 $shadow-color, -.06em 0 $shadow-color, .09em 0 $shadow-color, -.09em 0 $shadow-color, .12em 0 $shadow-color, -.12em 0 $shadow-color, .15em 0 $shadow-color, -.15em 0 $shadow-color;
}

@mixin customUnderline($underline-color) {
  text-decoration: none;
  background: linear-gradient($underline-color, $underline-color),
  linear-gradient($underline-color, $underline-color), linear-gradient($underline-color, $underline-color);
  // Second value of each is width of line
  background-size: .05em 1px, .05em 1px, 1px 1px;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-position: 0% 93%, 100% 93%, 0% 93%;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    background-position-y: 87%, 87%, 87%;
  }

  &:before,
  &:after,
  *,
  *:before,
  *:after {
    text-shadow: none;
  }
}
