// Header can also be inside articles or sections so limit to site header
body > header {
  background: $navbar-default-bg;
  color: $gray;
}

// Language selector
.language-selector {
  text-transform: capitalize;

  .dropdown-toggle {
    @include form-controls-style();
    text-transform: capitalize;
    padding: $padding-base-horizontal 15px;
  }

  .dropdown-toggle:focus,
  &.open .dropdown-toggle {
    box-shadow: none;
    border-color: $dropdown-bg;
    background-color: $navbar-default-link-active-color;

    // Requires nesting
    &.btn {
      background-color: $navbar-default-link-active-color;
      color: $link-color;
    }

    &:hover,
    &:active {
      background-color: $navbar-default-link-active-color;
      color: $link-color;
      box-shadow: none;
      border-color: $dropdown-bg;
    }
  }

  .dropdown-menu {
    border-color: $brand-primary;
    margin-top: 0;

    // Show focus on all links (override BS)
    > .active > a:focus {
      @include outline-focus();
    }
  }
}

// These must be separate to work
.site-header-search .form-control::-webkit-input-placeholder {
  color: $navbar-default-link-active-color;
}

.site-header-search .form-control:-moz-placeholder {
  color: $navbar-default-link-active-color;
}

.site-header-search .form-control::-moz-placeholder {
  color: $navbar-default-link-active-color;
}

.site-header-search .form-control:-ms-input-placeholder {
  color: $navbar-default-link-active-color;
}

// Main site header search
.site-header-search {

  .form-control,
  button[type="submit"] {
    height: $navbar-height;
    @include form-controls-style();

    &:focus {
      @include outline-focus();
    }
  }

  .form-control {
    box-shadow: none;
    padding: $padding-large-vertical $padding-large-horizontal;
    height: ($line-height-computed + ($padding-large-vertical * 2) + 2);
  }

  // Search button hover
  .btn {
    padding: $padding-large-vertical $padding-large-horizontal;

    &:active:hover,
    &:active:focus,
    &:active,
    &:focus,
    &:hover {
      background-color: $brand-primary;
      color: $btn-primary-color;
      border-color: $brand-primary;
    }
  }
}

// Branding dimension restrictions
.site-header-brand img {
  max-height: $line-height-computed * 6;
  max-width: $line-height-computed * 10;
  width: auto;
}

// Accessibilty links to navigation
.skip-links a {
  color: $navbar-default-link-color;
  background: $navbar-default-bg;
}

// Mobile only
// Site header
@media (max-width: $grid-float-breakpoint-max) {
  .site-header {
    min-height: 111px;

    .site-header-brand {
      margin-right: $line-height-computed * 5.5;
      font-size: 1.8em;
    }

    .navbar-toggle,
    .language-selector {
      position: absolute;
      top: $line-height-computed * 1.2;
    }

    .navbar-toggle {
      right: $line-height-computed * 0.6;
      padding: 17px 10px;
    }

    .language-selector {
      right: $line-height-computed * 2.8;
    }
  }
}
