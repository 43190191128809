.footer-site {
  border-top: 1px solid $gray-lighter;
  margin-top: 10px;
  padding: 30px 0;
}

.footer-nav-links a {
  margin-right: 30px;
  display: inline-block;
  margin-bottom: 10px;
}

@media (min-width: $screen-md-min) {
  .footer-social-links {
    float: right;
    text-align: right;

    a:last-child {
      margin-right: 0;
    }
  }

  .footer-govt-logo {
    float: right;
    margin-top: -3px;
  }
}
