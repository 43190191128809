// Sitemap formatting
.sitemap {
  border-bottom: 1px solid $table-border-color;
  list-style-type: none;
  margin: 40px 0 ($line-height-computed * 3);
  padding-left: 0;

  ul {
    list-style-type: none;
    padding-bottom: $padding-base-vertical;
  }
}

// Todo change class name to .sitemap-toggle in module
.page-toggle {
  padding: $padding-base-vertical 15px;
  display: inline-block;
  float: left;
}

.sitemap-link {
  padding: $padding-base-vertical 0;
  display: block;
  margin-left: 40px;
}

// Target first level only and give more spacing
.sitemap-initial {
  border-top: 1px solid $table-border-color;
  position: relative;

  &:hover {
    background-color: $table-bg-hover;
  }

  // Todo change class name in module to .sitemap-toggle-children
  > .page-toggle__children > .sitemap-link {
    padding-top: $padding-large-vertical;
    padding-bottom: $padding-large-vertical;
  }

  // Todo change class name to .sitemap-toggle in module
  > .page-toggle {
    padding-top: $padding-large-vertical;
    padding-bottom: $padding-large-vertical;
  }
}
