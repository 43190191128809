// Spacing (pattern introduced in BS v4)
// margin-top- x em size
.m-t-025 {
  margin-top: .25em;
}

.m-t-05 {
  margin-top: .5em;
}

.m-t-1 {
  margin-top: 1em;
}

.m-t-2 {
  margin-top: 2em;
}


// margin-right- x em size
.m-r-025 {
  margin-right: .25em;
}

.m-r-05 {
  margin-right: .5em;
}

.m-r-1 {
  margin-right: 1em;
}

.m-r-2 {
  margin-right: 2em;
}


// margin-bottom- x em size
.m-b-025 {
  margin-bottom: .25em;
}

.m-b-05 {
  margin-bottom: .5em;
}

.m-b-1 {
  margin-bottom: 1em;
}

.m-b-2 {
  margin-bottom: 2em;
}
