h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.lead,
ul,
ol {
  margin-bottom: $line-height-computed * .75;
}

h5,
.h5,
h6,
.h6 {
  line-height: $line-height-base;
}

.lead {
  font-weight: 200;
  font-size: floor(($font-size-base * 1.5));
}

blockquote {
  padding-top: 0;
  padding-bottom: 0;
  border-left: 3px solid $brand-primary;

  p,
  ul,
  ol {
    font-size: $font-size-base * 1.25;
    font-weight: 200;
  }

  .blockquote-footer:before {
    content: "\2014 "; // em dash, space
  }
}

h1,
.h1,
h2,
.h2 {
  letter-spacing: -.5px;
}

.captionImage { // sass-lint:disable-line class-name-format
  border-bottom: 2px solid $gray-lighter;

  p {
    color: $text-muted;
    font-size: $font-size-small;
  }
}

// Table styles

// Replicate th from Bootstrap
.table > thead > tr > td {
  border-top: 0;
  vertical-align: bottom;
  border-bottom: 2px solid $table-border-color;
}

.table-bordered,
.table-bordered-striped {
  border: 0;
  border-top: 1px solid $table-border-color;

  > thead:first-child > tr:first-child > {
    td,
    th {
      border-top: 1px solid $table-border-color;
    }
  }
}


// Nesting required to override bs styles
.table,
.table-striped,
.table-bordered {
  > tfoot > tr > {
    td,
    th {
      border-right: 0;
      border-bottom: 0;
      border-left: 0;
    }
  }
}

tfoot {
  td:first-child::before,
  th:first-child::before {
    content: "\2014 "; // em dash, space
  }
}

// Used typically under a header to provide additional info like author or dates.
.meta-info {
  header + &,
  h1 + &,
  .h1 + &,
  h2 + &,
  .h2 + &,
  h3 + &,
  .h3 + &,
  h4 + &,
  .h4 + & {
    margin-top: -$line-height-computed / 2;
  }
}

.Footnote, .FootnoteText {
  font-size: 0.8em; 
}
