body > header .open > a:focus {
  @include outline-focus();
}

.site-header {
  padding-top: 30px;
  padding-bottom: 30px;

  .language-selector {
    margin-right: 5px;

    .fa-language {
      margin-right: $font-size-base / 2;
    }
  }
}

.site-header-brand {
  overflow: visible;
  float: left;
  margin-top: 3px;
}

.site-header-brand a {
  font-size: $font-size-h3;
  font-weight: $headings-font-weight;
  line-height: 1em;
}

.site-header-brand-tagline {
  display: block;
}

.site-header-brand-link,
.site-header-brand-link-default {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

//Accessibilty links to navigation
.skip-links a {
  height: 1px;
  padding: $padding-small-vertical $padding-small-horizontal;
  position: absolute;
  left: -100%;
  overflow: hidden;
  top: -100%;
  width: 1px;

  &:focus,
  &:active {
    display: inline-block;
    height: auto;
    left: 0;
    margin-bottom: 20px;
    overflow: visible;
    position: static;
    top: 0;
    width: auto;
  }
}
