.carousel {
  // The navbar has a 1px border on the bottom, so adding it to the margin
  margin-top: -($line-height-computed + 1);
  margin-bottom: $jumbotron-padding * 2;

  .container {
    position: relative;
  }

  // Slides
  .item {
    padding-top: $jumbotron-padding * 2;
    padding-bottom: $jumbotron-padding * 3;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $navbar-default-bg;
    min-height: 360px;
    border-top: 1px solid rgba($theme-bg-lighter, .5);
    color: $carousel-text-color;

    // If links are not on image use dark text shadow to break coloured underlines with decenders
    a {
      @include textShadowToCropUnderline ($theme-bg);
      color: $carousel-text-color;
    }

    h1,
    h2,
    h3,
    p {
      text-shadow: $carousel-text-shadow;
      color: $carousel-text-color;
    }

    // Remove lighter border-top if there is an image
    &.carousel-has-image {
      border-top-color: $theme-bg;

      a {
        text-shadow: $carousel-text-shadow;
      }
    }

    .btn {
      margin-left: 10px;
      margin-right: 10px;
    }

    .btn-link {
      position: relative;
      background-image: linear-gradient(to bottom, lighten($brand-primary, 20%) 50%, rgba($brand-primary, 0) 50%);
      background-repeat: repeat-x;
      // Height of line, repeated dot for underline width (only half of size is seen)
      background-size: 2px 2px;
      // Position of line from top
      background-position: 0 ($line-height-computed + $padding-large-vertical);
      padding-left: 0;
      padding-right: 0;

      &:hover {
        background-image: linear-gradient(to bottom, lighten($brand-primary, 40%) 50%, rgba($brand-primary, 0) 50%);
      }
    }
  }
}

// Carousel content
.carousel-content > p {
  // Mimic .lead
  // Increase distance between text and actions
  margin-bottom: $line-height-computed * 1.25;
  font-size: floor(($font-size-base * 1.15));
  font-weight: 300;
  line-height: 1.4;

  @media (min-width: $screen-sm-min) {
    font-size: ($font-size-base * 1.5);
  }

  // Custom carousel link style for theme
  a {
    @include customUnderline(lighten($brand-primary, 20%));

    &:hover {
      @include customUnderline(lighten($brand-primary, 10%));
      background-color: rgba($theme-bg, .2);
    }
  }
}

// Controls wrapper
.carousel-controls-wrapper {
  position: absolute;
  bottom: 10px;
  // Center
  left: 50%;
  z-index: 2;
  // Not to obstruct left/right arrows
  width: 60%;
  // Back half of width to center
  margin-left: -30%;
  text-align: center;
}

// Controls navigate left or right
.carousel-control {
  z-index: 2;
  border: 0;

  &.left,
  &.right {
    background-image: none;
  }

  &:hover {
    background-color: rgba($theme-bg-lighter, .2);
  }

  &:focus {
    @include outline-focus;
  }
}


// Carousel slides indicators
.carousel-indicators {
  display: inline-block;
  position: static;
  width: auto;
  margin: 0;
  padding: 7px 0 7px 14px;

  li,
  li.active {
    margin-right: 4px;
    margin-left: 4px;

    &:hover {
      border-color: rgba($carousel-text-color, .8);
    }
  }
}

// Play and pause button
.carousel-play-controls {
  display: inline-block;
  bottom: $padding-small-horizontal;
  bottom: 2px;
  position: relative;

  .btn-link {
    color: $carousel-text-color;

    &:hover {
      color: rgba($carousel-text-color, .8);
    }
  }
}

// If only single carousel item center text vertically as no carousel controls
.carousel-static .item {
  padding-top: $jumbotron-padding * 3;
}

// Overlay carousel mask
.carousel-mask {
  position: absolute;
  background: $jumbotron-bg;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .carousel-has-image & {
    background: $carousel-mask-bg-color;
  }
}
