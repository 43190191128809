// Watea theme specific variables and overrides
@import "./variables";

// Import starter theme styles
@import "./starter/main";

// Watea theme mixins
@import "./utils/mixins";

// Watea theme styles
@import "./typography";
@import "./components/buttons";
@import "./components/carousel";
@import "./components/footer";
@import "./components/forms";
@import "./components/header";
@import "./components/nav";
@import "./components/pages";
