.news-events-archive .nav a {
  padding: 3px 7px;
  font-size: $font-size-small;
}

.news-events-archive-year {
  display: inline-block;
  margin-top: 3px;
  vertical-align: top;
  margin-right: 5px;
}

.news-events-item-figure {
  margin-left: 18px;
  margin-top: 24px;
  margin-bottom: 18px;
}
