// Main navigation
@media (max-width: $grid-float-breakpoint-max) {
  .navbar-toggle {
    padding: $padding-large-vertical $padding-base-horizontal;
    margin-right: 0;
  }
}

// Override BS default behavior
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover {
  background-color: transparent;
}

.main-nav {
  .navbar {
    z-index: $zindex-navbar;
  }

  .navbar-default {
    background-color: $color-akaroa;

    .navbar-nav > li > a,
    .navbar-touch-caret,
    .dropdown-menu > .active > a {
      color: $gray;
    }

    .navbar-nav > .active {
      & > a,
      .navbar-touch-caret {
        color: $color-white;
      }

      &:hover,
      &:focus {
        & > a {
          color: $color-white;
        }
      }
    }

    .navbar-nav .open:not(.active) a:hover {
      color: $gray-dark;
    }
  }

  .nav-item.current > a {
    background-color: $theme-bg;

    &:focus,
    &:hover {
      background-color: $theme-bg;
    }
  }

  // Change caret icon for dropdown to .fa-angle-down
  .fa-caret-down:before {
    content: "\F107";
  }

  // Change caret icon for active dropdown to .fa-angle-up
  .fa-caret-up:before {
    content: "\F106";
  }

  .dropdown {
    &:hover .navbar-touch-caret,
    &:focus .navbar-touch-caret {
      background-color: transparent;
    }

    // When active
    &.current {
      .navbar-touch-caret,
      &:hover .navbar-touch-caret,
      &:focus .navbar-touch-caret {
        background: $theme-bg;

        &:hover,
        &:focus {
          background-color: $theme-bg;
        }
      }
    }
  }

  // Mobile only
  @media (max-width: $grid-float-breakpoint-max) {

    .navbar-nav {
      margin-top: 0;
      margin-bottom: 0;
    }

    .navbar-collapse {
      box-shadow: none;
    }

    // Add hover border to left side
    .nav-item > a,
    .dropdown-menu a {
      position: relative;

      &:before {
        transition: background-color 300ms ease-out;
        width: $nav-hover-border-width;
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:hover:before,
      &:focus:before {
        background: $theme-bg;
      }
    }

    // Dropdown
    .dropdown {
      // Change caret direction pointing up
      &.open .navbar-touch-caret .fa-caret-down:before {
        content: "\f106";
      }

      .navbar-touch-caret {
        padding-left: $navbar-padding-horizontal;
      }

      .dropdown-menu {
        padding: 0;
        // Requires nesting
        background-color: $theme-bg-lighter;
      }
    }

    .open .dropdown-menu > li > a {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  // Desktop only
  @media (min-width: $grid-float-breakpoint-max) {

    .nav-item > a {
      padding-top: $navbar-padding-vertical;
      padding-bottom: $navbar-padding-vertical;
    }

    // 1st level
    .nav-item {
      > .section {
        border-bottom: $nav-hover-border-width solid $navbar-default-brand-hover-bg;
      }

      > .link {
        border-bottom: $nav-hover-border-width solid $navbar-default-brand-hover-bg;
        transition: border-color 300ms ease-out;

        &:focus,
        &:hover {
          border-bottom-color: $theme-bg;
        }
      }

      > .current {
        border-bottom: $nav-hover-border-width solid $theme-bg;

        &:focus,
        &:hover {
          border-bottom-color: $theme-bg;
        }
      }

      > .dropdown-menu {
        margin-top: (-$nav-hover-border-width - 1px);
        margin-left: -1px;
      }
    }

    // Requires further nesting
    .navbar-nav > .nav-item {
      margin-bottom: -$nav-hover-border-width;
    }

    // 1st level with dropdown
    .dropdown {
      // Allow space for caret
      padding-right: $navbar-padding-horizontal + 6px; // 6px approx half width of icon

      > .link {
        &:focus,
        &:hover {
          border-bottom-color: $navbar-default-brand-hover-bg;
        }
      }

      &.current .navbar-touch-caret {
        border-bottom: $nav-hover-border-width solid $theme-bg;
      }
    }
  }
}

// Add nav--pills modifier to be able to use nav-pills class without bootstrap-accessibility plugin applying
// attributes which don't actually meet WCAG standards (See AvailableMonths.ss)
.nav--pills {
  @extend .nav-pills;
}
