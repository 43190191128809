.footer-site {
  margin-top: 0;
  background-color: $navbar-default-bg;
  padding: ($line-height-computed * 1.8) 0;

  // Horizontal divder line
  hr {
    border-top: 1px solid $theme-bg-lighter;
    margin-bottom: $line-height-computed * 1.5;

    @media (min-width: $screen-md-min) {
      border-color: transparent;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  a:hover,
  p {
    color: $navbar-default-link-hover-color;
  }
}

// NZ Gov logo
.footer-govt-logo a {
  position: relative;
  display: inline-block;
  margin-bottom: $line-height-computed;
}

// Footer navigation and Copyright
.footer-nav-links a {
  color: $navbar-default-link-hover-color;
  margin-right: $line-height-computed;
}

// Social media icons
.footer-social-links .fa {
  font-size: 2.2rem;
  margin-bottom: $line-height-computed * 1.2;
}

// Secondary branding logo (defaults to CWP logo)
.footer-brand-secondary {
  &::after {
    content: "";
    width: 0;
    height: calc(100% + 12px);
    position: absolute;
    border: 1px solid $theme-bg-lighter;
    right: 10px;
    top: -6px;
  }

  img {
    margin-right: $line-height-computed * 1.1;
    max-height: $line-height-computed * 6;
    max-width: $line-height-computed * 10;
    width: auto;
  }
}
